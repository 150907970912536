import jwtDecode from 'jwt-decode';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { GiftCardCheckoutService } from '../../../services/GiftCardCheckoutService';
import { GiftCardService } from '../../../services/GiftCardService';

export type AppServices = {
  controllerParams: ControllerParams;
  giftCardCheckoutService: GiftCardCheckoutService;
  giftCardService: GiftCardService;
};

export const initializeServices = async (
  controllerParams: ControllerParams,
): Promise<AppServices> => {
  const giftCardCheckoutService = new GiftCardCheckoutService(controllerParams);
  const giftCardService = new GiftCardService(controllerParams);

  const { metaSiteId } = jwtDecode(
    controllerParams.controllerConfig.appParams.instance,
  ) as {
    metaSiteId: String;
  };

  controllerParams.flowAPI?.bi?.updateDefaults({
    appDefId: controllerParams.controllerConfig.appParams.appDefinitionId,
    instanceId: controllerParams.controllerConfig.appParams.instanceId,
    msid: metaSiteId,
  });

  return {
    controllerParams,
    giftCardCheckoutService,
    giftCardService,
  };
};
